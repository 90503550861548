import { Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import circloLogo from '../../assets/imgs/circlo/circlo_logo_horizontal.png';
import { useGtXs } from '../../hooks/useGtXs';
import { CircloHtmlHead } from '../HtmlHead/HtmlHead';
import LinkToSite from '../LinkToSite/LinkToSite';
import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
    children: ReactNode
}

const AuthLayout: FC<AuthLayoutProps> = props => {
    const { children } = props

    const gtXs = useGtXs()

    const span = gtXs ? 8 : 12

    return (
        <Grid container className={styles.AuthLayout}>
            <CircloHtmlHead />
            {gtXs && <Grid item container xs={4} className={styles.Brand} >
                <Grid container justifyContent="center" alignItems="center">
                    <LinkToSite>
                        <img src={circloLogo} alt="circlo logo" />
                    </LinkToSite>
                </Grid>
                <div className={styles.Footer}>
                    <LinkToSite>
                        <span>wwww.pedrosapt.com</span>
                    </LinkToSite>

                </div>

            </Grid>}
            <Grid item container xs={span}>
                <Grid container justifyContent="center" alignItems="center">
                    {children}
                </Grid>
            </Grid>
        </Grid >
    )
};

export default AuthLayout;
