import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Grid, IconButton, Toolbar } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import circloLogo from '../../assets/imgs/circlo/circlo_logo_horizontal.png';
import circloLogoMobile from '../../assets/imgs/circlo/circlo_mobile.png';
import { useGtXs } from '../../hooks/useGtXs';
import { useAuth } from '../../services/auth';
import Config from '../../services/config';
import FiltersMenu from '../FiltersMenu/FiltersMenu';
import { useSidemenu } from '../Layout/LayoutProvider';
import UserMenu from '../UserMenu/UserMenu';
import styles from './Header.module.scss';

const Header = () => {
    const context = useSidemenu()
    const gtXs = useGtXs()
    const navigate = useNavigate()
    const auth = useAuth()

    const handle = () => {
        context.setIsSidemenuOpen(true)
    }

    const handleClick = () => {
        navigate(Config.START_ROUTE)
    }

    const logo = gtXs ? circloLogo : circloLogoMobile

    return (
        <AppBar position="fixed">
            <Toolbar className={styles.AppToolbar}>

                {auth?.user ?
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <IconButton color="inherit" aria-label="menu" onClick={handle}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <img src={logo} alt="circlo logo" className={styles.Logo} onClick={handleClick} />
                        </Grid>
                        <Grid item justifyContent="flex-end">
                            <Grid container justifyContent="flex-end">
                                {gtXs ? null : <FiltersMenu />}
                                <UserMenu />
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                            <img src={logo} alt="circlo logo" className={styles.Logo} onClick={handleClick} />
                        </Grid>
                    </Grid>
                }
            </Toolbar>
        </AppBar >
    );
};

export default Header;
