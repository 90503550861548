import { Grid } from '@mui/material';
import styles from './Footer.module.scss';

const Footer = () => (
    <Grid container className={styles.Footer} justifyContent="center">
        <p>
            <a href="http://pedrosapt.com" style={{ color: "#ffffff" }} target="_blank" rel="noreferrer">www.pedrosapt.com</a>
        </p>
    </Grid>
);

export default Footer;
