import { Helmet } from "react-helmet";
import circloFavicon from '../../assets/imgs/circlo/circlo_favicon.png';
import pedrosaFavicon from '../../assets/imgs/foursource/pedrosa_favicon.png';

export const CircloHtmlHead = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Circlo™ - engineered by Pedrosa</title>
            <link rel="icon" type="image/png" href={circloFavicon} sizes="16x16" />
            <link rel="canonical" href="https://circlo.pt" />
        </Helmet>
    )
}

export const FoursourceHtmlHead = () => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Foursource Pedrosa</title>
            <link rel="icon" type="image/png" href={pedrosaFavicon} sizes="16x16" />
            <link rel="canonical" href="https://foursource.mypedrosa.com/" />
        </Helmet>
    )
}
