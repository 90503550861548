import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { parse, stringify } from 'query-string';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './App.css';
import CreateQuote from './components/CreateQuote/CreateQuote';
import CreateUser from './components/CreateUser/CreateUser';
import EventTracking from './components/EventTracking/EventTracking';
import Feedback from './components/Feedback/Feedback';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import FoursourceHome from './components/FoursourceHome/FoursourceHome';
import FoursourceLayout from './components/FoursourceLayout/FoursourceLayout';
import FoursourceThanks from './components/FoursourceThanks/FoursourceThanks';
import Login from './components/Login/Login';
import NotFound from './components/NotFound/NotFound';
import Posters from './components/Posters/Posters';
import Protect from './components/Protect/Protect';
import Registration from './components/Registration/Registration';
import SetPassword from './components/SetPassword/SetPassword';
import UserRedirect from './components/UserRedirect/UserRedirect';
import AuthProvider from './services/auth';
import { client } from './services/client';
import { circloTheme, selectivaTheme } from './themes';

function App() {
    const circlo = ["circlo.pt", "www.circlo.pt"]
    const host = window.location.host
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <SnackbarProvider>
                    <EventTracking>
                        <BrowserRouter>
                            <QueryParamProvider
                                adapter={ReactRouter6Adapter}
                                options={{
                                    searchStringToObject: parse,
                                    objectToSearchString: stringify,
                                }}
                            >
                                {circlo.includes(host) ?
                                    <ThemeProvider theme={circloTheme}>
                                        <Routes>
                                            <Route path="/" element={<Protect><Posters /></Protect>} />
                                            <Route path="/meshes" element={<Protect><Posters /></Protect>} />
                                            <Route path="/finishes" element={<Protect><Posters /></Protect>} />
                                            <Route path="/login" element={<UserRedirect><Login /></UserRedirect>} />
                                            <Route path="/registration" element={<UserRedirect><Registration /></UserRedirect>} />
                                            <Route path="/posters" element={<Protect><Posters /></Protect>} />
                                            <Route path="/create-user" element={<Protect><CreateUser /></Protect>} />
                                            <Route path="/forgot-password" element={<UserRedirect><ForgotPassword /></UserRedirect>} />
                                            <Route path="/set-password" element={<UserRedirect><SetPassword /></UserRedirect>} />
                                            <Route path="/feedback" element={<Feedback />} />
                                        </Routes>
                                    </ThemeProvider>
                                    :
                                    <ThemeProvider theme={selectivaTheme}>
                                        <Routes>
                                            <Route path="/" element={<FoursourceLayout><FoursourceHome /></FoursourceLayout>} />
                                            <Route path="/create-quote" element={<FoursourceLayout><CreateQuote /></FoursourceLayout>} />
                                            <Route path="/thanks" element={<FoursourceLayout><FoursourceThanks /></FoursourceLayout>} />
                                            <Route path="*" element={<FoursourceLayout><NotFound /></FoursourceLayout>} />
                                        </Routes>
                                    </ThemeProvider>
                                }
                            </QueryParamProvider>
                        </BrowserRouter>
                    </EventTracking>
                </SnackbarProvider>
            </AuthProvider>
        </ApolloProvider>
    );
}

export default App;
